import { useEffect, useState } from "react";
import AOS from "aos";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { motion } from "framer-motion";

import "aos/dist/aos.css"; // Import stylów
import './style/app.css';

import logo from './img/logo-scanmeme.png'
import astra from './img/astra.png'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import icon4 from './img/icon4.png'
import icon5 from './img/icon5.png'
import icon6 from './img/icon6.png'
import icon7 from './img/icon7.png'
import icon8 from './img/icon8.png'
import icon9 from './img/icon9.png'
import icon10 from './img/icon10.png'
import icon11 from './img/icon11.png'
import icon12 from './img/icon12.png'
import icon13 from './img/icon13.png'
import icon14 from './img/icon14.png'
import xks from './img/x.png'
import logo_footer from './img/logo-footer.png'
import water_logo from './img/mask.png'

import tags from './img/svg/tags_codes'


function App() {

  const [clicked, setClicked] = useState(false)
  const [clickedId, setClickedId] = useState(null)
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scrollY, setScrollY] = useState(0);

  const data = [
    {
      icon: icon1,
      title: 'Real-Time X (Twitter) Engagement',
      info: 'Watch tweets, retweets, comments, and likes as they happen. See how many verified accounts and followers are involved to measure true hype.'
    },
    {
      icon: icon2,
      title: 'Next-Level Dev Detection',
      info: 'Check if the devs have a history of good or bad projects. Find out who funds them — big investors, crypto exchanges, or scammers — for real transparency.'
    },
    {
      icon: icon3,
      title: 'Social History',
      info: 'See if the same Twitter, Telegram, or website links were used in past projects'
    },
    {
      icon: icon4,
      title: 'Duplicate Image Detection',
      info: 'Warn if a token’s image is reused, protecting you from copy-paste branding tricks.'
    },
    {
      icon: icon5,
      title: 'DexBoost Tracking',
      info: 'Follow DexBoost activity to see if a token is truly gaining value or just being pumped.'
    },
    {
      icon: icon6,
      title: 'Fake Volume Bot Detection',
      info: 'Spot any fishy moves boosting trading volume or faking price jumps, so you know the real hype.'
    },
    {
      icon: icon7,
      title: 'Sniper Wallets & Early Entries',
      info: 'Identify wallets that jump in first to see who’s buying before everyone else.'
    },
    {
      icon: astra,
      title: '',
      info: ''
    },
    {
      icon: icon8,
      title: 'Bundled Tokens',
      info: 'Reveal tokens that share the same developer or entity, uncovering hidden ties and shared funds.'
    },
    {
      icon: icon9,
      title: 'Duplicate Token Ticker Detection',
      info: 'Check if more than one token has the same ticker, helping you avoid confusion or scams.'
    },
    {
      icon: icon10,
      title: 'Holder Behavior',
      info: 'Label holders as new wallets, traders, or scammers, so you know who really owns the tokens.'
    },
    {
      icon: icon11,
      title: 'Top Holder Analysis',
      info: 'Show the biggest wallets and whether they’re holding long-term or setting up to sell.'
    },
    {
      icon: icon12,
      title: 'Selloff Watch',
      info: 'Check sell-offs, showing if new wallets, traders, or scammers are dumping'
    },
    {
      icon: icon13,
      title: 'MarketMaker Activity',
      info: 'Spot if MarketMakers is behind of token'
    },
    {
      icon: icon14,
      title: 'Basic Stats',
      info: 'Get trading volume, holder counts, and other key metrics for a quick look at any token’s overall health.'
    }
  ]

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <>
      <div className="App">
      
        <button className="btn-connect" onClick={() => window.open('https://x.com/scanmeme', '_blank')}>Connect</button>

        <div className="App-header">
        
          <motion.img
            src={water_logo}
            alt="moving"
            className="mask"
            animate={{
              y: (position.y - window.innerHeight / 1.5) * 0.05 + scrollY * 0.1, // Parallax scroll effect
            }}
            transition={{ type: "spring", stiffness: 100 }}
          />

          <img className='logo' src={logo} alt='solan-scanmem'/>
          <h1>Smart and Powerful Insights for Pump<span className='purple'>.</span>fun Tokens<span className='green'>.</span></h1>
          <p>
            We’re bringing you the most advanced AI analytics for Pump.fun tokens. Whether you’re an investor, 
            researcher, or just keeping an eye on trends, SCANMEME.AI gives you the insights that matter
          </p>
          <button className='btn' onClick={() => window.open('https://x.com/scanmeme', '_blank')} style={{margin: '85px 0 120px 0'}}>Start Scanning Now</button>
        </div>

        <h2 
          // className="gradient" 
          style={{marginTop: '80px'}}
        >
          The Most Advanced AI for Pump.fun Analytics
        </h2>
        <p>Uncover hidden risks, track wallet movements, and stay ahead of the game.</p>

        <div className="features">
          {data.map((o,i) => {
            return (
              <div 
                key={i}
                className={`features-item ${o.title === '' ? 'astra' : ''}`} 
                // data-aos="fade-up" 
                // data-aos-delay={i * 50}
              >
                <img src={o.icon} alt={o.title} />
                {o.title !== '' &&
                  <>
                    <h3>{o.title}</h3>
                    <p>{o.info}</p>               
                  </>
                }
              </div>
            )
          })}
        </div>

        <h2 style={{marginTop: '150px'}}>Believe in something</h2>
        <p>Show the world you're a Solana project</p>
        <button 
          className='btn-2' 
          style={{margin: '30px 0 60px 0'}}
          onClick={() => window.open('https://drive.google.com/drive/folders/12dvUXOcoZmBO44PY6qKkAcqHU6112R0n', '_blank')}
        >
          Download All!
        </button>
        
        <div className="tags">
          {tags.map((o,i) => {
            return (
              <div className="tags-item" key={i}>
                <CopyToClipboard text={o.code}>
                  <div className="svg-box">
                    <span style={clicked && clickedId === i ? {opacity: '1'} : {opacity: '0'}}>Copied!</span>
                    <img 
                      src={o.src} 
                      onClick={() => {
                        setClicked(true)
                        setClickedId(o.tag - 1)

                        setTimeout(()=>{
                          setClicked(false)
                          setClickedId(null)
                        },2000)
                      }} 
                      alt="" 
                      class={i < 4 ? 'row1' : 'row2'}
                    />
                  </div>
                </CopyToClipboard>              
              </div>

            )
          })}
        </div>
      </div>  

      <div className="footer">
        <button 
          className='btn-3' 
          style={{margin: '0px 0 60px 0'}}
          onClick={() => window.open('https://x.com/scanmeme', '_blank')}
        >
          Check us out on
          <img src={xks} alt=""/>
        </button>
        <img src={logo_footer} alt=""/>
        <div>
          © 2025 Scan.meme<br/>
          Privacy Policy | Terms of Service
        </div>
      </div>
    </>

  );
}

export default App;
